var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"history"},[_c('nav-header'),_c('div',{staticClass:"log-wrap"},[_c('div',{staticClass:"log-content"},[_c('div',{staticClass:"log-title"},[_vm._v("转换记录")]),_c('div',{staticClass:"log-text"},[_vm._v("转换记录将只保留最近30天的转换记录。")]),_c('div',{staticClass:"log-list"},[(_vm.showEmpty)?_c('div',{staticClass:"log-empty"},[_c('img',{staticClass:"log-empty-img",attrs:{"src":require("../../assets/img/history/empty.png"),"alt":""}})]):_c('el-table',{attrs:{"width":"1200px","header-cell-style":{ background: '#F9F9F9' },"data":_vm.logData,"stripe":true}},[_c('el-table-column',{attrs:{"prop":"updateTime","label":"时间","width":"250px"}}),_c('el-table-column',{attrs:{"prop":"fileName","label":"源文件","width":"420px","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"transferType","label":"文件处理","width":"240px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.transferType === 'pdfToWord')?_c('span',[_vm._v("转换为Word（*Word）")]):(scope.row.transferType === 'pdfToExcel')?_c('span',[_vm._v("转换为Exce（*Excel）")]):(scope.row.transferType === 'pdfToPpt')?_c('span',[_vm._v("转换为Ppt（*Ppt）")]):(scope.row.transferType === 'pdfToJpg')?_c('span',[_vm._v("转换为Jpg（*Jpg）")]):(
                  scope.row.transferType === 'wordToPdf' ||
                  scope.row.transferType === 'excelToPdf' ||
                  scope.row.transferType === 'pptToPdf' ||
                  scope.row.transferType === 'imgToPdf' ||
                  scope.row.transferType === 'cadToPdf' ||
                  scope.row.transferType === 'ofdToPdf'
                )?_c('span',[_vm._v("转换为PDF（*Pdf）")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"convertStatus","label":"状态","width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status === 200)?_c('span',[_vm._v("已完成")]):_vm._e(),(scope.row.status === 300)?_c('span',[_vm._v("转换失败")]):_vm._e(),(scope.row.status === 100 || scope.row.status === 0)?_c('span',[_vm._v("转换中")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"操作","width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"history-download",staticStyle:{"text-decoration":"none","display":"flex","align-items":"center"},on:{"click":function($event){return _vm.handlerDownload(scope.row)}}},[_c('div',{staticStyle:{"width":"25px","height":"25px","margin-right":"6px"}},[(scope.row.transferType === 'pdfToWord')?_c('img',{attrs:{"src":require("../../assets/img/home/upload/i-word.png"),"alt":""}}):(scope.row.transferType === 'pdfToExcel')?_c('img',{attrs:{"src":require("../../assets/img/home/upload/i-excel.png"),"alt":""}}):(scope.row.transferType === 'pdfToPpt')?_c('img',{attrs:{"src":require("../../assets/img/home/upload/i-ppt.png"),"alt":""}}):(scope.row.transferType === 'pdfToJpg')?_c('img',{attrs:{"src":require("../../assets/img/home/upload/i-img.png"),"alt":""}}):(
                        scope.row.transferType === 'wordToPdf' ||
                        scope.row.transferType === 'excelToPdf' ||
                        scope.row.transferType === 'pptToPdf' ||
                        scope.row.transferType === 'imgToPdf' ||
                        scope.row.transferType === 'cadToPdf' ||
                        scope.row.transferType === 'ofdToPdf'
                      )?_c('img',{attrs:{"src":require("../../assets/img/home/upload/i-pdf.png"),"alt":""}}):_vm._e()]),_c('span',[_vm._v("下载")])]),_c('span',{staticClass:"history-delete",on:{"click":function($event){return _vm.deleteData(scope.row)}}},[_vm._v("删除")])])]}}])})],1),(!(_vm.logData.length === 0))?_c('el-pagination',{staticStyle:{"text-align":"right","margin-top":"20px"},attrs:{"background":"","layout":"prev, pager, next, total","current-page":_vm.currentPage,"total":_vm.total},on:{"current-change":_vm.currentChange}}):_vm._e()],1)])]),(_vm.delShow)?_c('div',{staticClass:"del-dialog"},[_c('div',{staticClass:"del-mask"},[_c('div',{staticClass:"del-close",on:{"click":function($event){_vm.delShow = false}}},[_c('img',{attrs:{"src":require("../../assets/img/history/close.png"),"alt":""}})]),_vm._m(0),_c('div',{staticClass:"del-text del-text-top"},[_vm._v("是否永久删除该转换记录？")]),_c('div',{staticClass:"del-text"},[_vm._v(" 删除后，将不再保留本次转换的任何记录，包括转换文档。 ")]),_c('div',{staticClass:"del-btn-wrap"},[_c('div',{staticClass:"del-channel del-btn",on:{"click":_vm.delChanel}},[_vm._v("取消")]),_c('div',{staticClass:"del-ok del-btn",on:{"click":_vm.delOk}},[_vm._v("确定")])])])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"del-icon"},[_c('img',{attrs:{"src":require("../../assets/img/history/danger.png"),"alt":""}})])}]

export { render, staticRenderFns }