<template>
  <!-- 转换记录 -->
  <div class="history">
    <!-- header -->
    <nav-header></nav-header>
    <!-- content -->
    <div class="log-wrap">
      <div class="log-content">
        <div class="log-title">转换记录</div>
        <div class="log-text">转换记录将只保留最近30天的转换记录。</div>
        <div class="log-list">
          <div class="log-empty" v-if="showEmpty">
            <img
              class="log-empty-img"
              src="../../assets/img/history/empty.png"
              alt
            />
          </div>
          <el-table
            width="1200px"
            :header-cell-style="{ background: '#F9F9F9' }"
            v-else
            :data="logData"
            :stripe="true"
          >
            <el-table-column
              prop="updateTime"
              label="时间"
              width="250px"
            ></el-table-column>
            <el-table-column
              prop="fileName"
              label="源文件"
              width="420px"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="transferType" label="文件处理" width="240px">
              <template slot-scope="scope">
                <span v-if="scope.row.transferType === 'pdfToWord'"
                  >转换为Word（*Word）</span
                >
                <span v-else-if="scope.row.transferType === 'pdfToExcel'"
                  >转换为Exce（*Excel）</span
                >
                <span v-else-if="scope.row.transferType === 'pdfToPpt'"
                  >转换为Ppt（*Ppt）</span
                >
                <span v-else-if="scope.row.transferType === 'pdfToJpg'"
                  >转换为Jpg（*Jpg）</span
                >
                <span
                  v-else-if="
                    scope.row.transferType === 'wordToPdf' ||
                    scope.row.transferType === 'excelToPdf' ||
                    scope.row.transferType === 'pptToPdf' ||
                    scope.row.transferType === 'imgToPdf' ||
                    scope.row.transferType === 'cadToPdf' ||
                    scope.row.transferType === 'ofdToPdf'
                  "
                  >转换为PDF（*Pdf）</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="convertStatus" label="状态" width="150px">
              <template slot-scope="scope">
                <span v-if="scope.row.status === 200">已完成</span>
                <span v-if="scope.row.status === 300">转换失败</span>
                <span v-if="scope.row.status === 100 || scope.row.status === 0"
                  >转换中</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" width="140px">
              <template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  <div
                    style="
                      text-decoration: none;
                      display: flex;
                      align-items: center;
                    "
                    @click="handlerDownload(scope.row)"
                    class="history-download"
                  >
                    <div style="width: 25px; height: 25px; margin-right: 6px">
                      <img
                        v-if="scope.row.transferType === 'pdfToWord'"
                        src="../../assets/img/home/upload/i-word.png"
                        alt
                      />
                      <img
                        v-else-if="scope.row.transferType === 'pdfToExcel'"
                        src="../../assets/img/home/upload/i-excel.png"
                        alt
                      />
                      <img
                        v-else-if="scope.row.transferType === 'pdfToPpt'"
                        src="../../assets/img/home/upload/i-ppt.png"
                        alt
                      />
                      <img
                        v-else-if="scope.row.transferType === 'pdfToJpg'"
                        src="../../assets/img/home/upload/i-img.png"
                        alt
                      />
                      <img
                        v-else-if="
                          scope.row.transferType === 'wordToPdf' ||
                          scope.row.transferType === 'excelToPdf' ||
                          scope.row.transferType === 'pptToPdf' ||
                          scope.row.transferType === 'imgToPdf' ||
                          scope.row.transferType === 'cadToPdf' ||
                          scope.row.transferType === 'ofdToPdf'
                        "
                        src="../../assets/img/home/upload/i-pdf.png"
                        alt
                      />
                      <!-- <img v-else-if="scope.row.transferType === 'zip'" src="../../assets/img/i-zip.png" alt=""> -->
                    </div>
                    <span>下载</span>
                  </div>
                  <span class="history-delete" @click="deleteData(scope.row)"
                    >删除</span
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="!(logData.length === 0)"
            background
            style="text-align: right; margin-top: 20px"
            layout="prev, pager, next, total"
            :current-page="currentPage"
            @current-change="currentChange"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <!-- 删除dialog -->
    <div class="del-dialog" v-if="delShow">
      <div class="del-mask">
        <div class="del-close" @click="delShow = false">
          <img src="../../assets/img/history/close.png" alt />
        </div>
        <div class="del-icon">
          <img src="../../assets/img/history/danger.png" alt />
        </div>
        <div class="del-text del-text-top">是否永久删除该转换记录？</div>
        <div class="del-text">
          删除后，将不再保留本次转换的任何记录，包括转换文档。
        </div>
        <div class="del-btn-wrap">
          <div class="del-channel del-btn" @click="delChanel">取消</div>
          <div class="del-ok del-btn" @click="delOk">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "@/components/NavHeader";
import { hxsGet } from "@/apis/index";
// import axios from "axios";
export default {
  components: {
    NavHeader
  },
  data() {
    return {
      currentPage: 1,
      total: 0,
      logData: [],
      delShow: false,
      delTaskId: "",
      showEmpty: false
    };
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/");
    } else {
      this.getList(1);
    }
  },
  methods: {
    // 获取列表
    getList(i) {
      const tableLoading = this.$loading({
        lock: true,
        text: "正在下载转换记录",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      hxsGet(
        "doconline/open/file/convert/list.html?token=" +
          localStorage.getItem("token") +
          "&page=" +
          i +
          "&size=10"
      )
        .then((res) => {
          tableLoading.close();
          if (res.data.totalPages < i && res.data.data.length < 1) {
            this.getList(i - 1);
          }
          if (res.data.data.length <= 0) {
            this.showEmpty = true;
          } else {
            this.showEmpty = false;
          }
          this.total = res.data.total;
          this.logData = res.data.data;
        })
        .catch(() => {
          tableLoading.close();
        });
    },
    // 点击删除
    deleteData(row) {
      if (window._czc) {
        window._czc.push([
          "_trackEvent",
          "DFP在线转换",
          "转换记录相关",
          "删除按钮点击次数"
        ]);
      }
      this.delTaskId = row.taskId;
      this.delShow = true;
    },
    // 删除弹窗确定
    delOk() {
      if (window._czc) {
        window._czc.push([
          "_trackEvent",
          "DFP在线转换",
          "删除转换文档二次确认框",
          "删除确认按钮点击次数"
        ]);
      }
      hxsGet(
        "doconline/open/file/convert/delete.html?token=" +
          localStorage.getItem("token") +
          "&taskId=" +
          this.delTaskId
      )
        .then((res) => {
          this.delShow = false;
          if (res.success) {
            this.getList(this.currentPage);
          }
        })
        .catch(() => {
          this.delShow = false;
        });
    },
    // 删除弹窗取消
    delChanel() {
      this.delShow = false;
      if (window._czc) {
        window._czc.push([
          "_trackEvent",
          "DFP在线转换",
          "删除转换文档二次确认框",
          "取消删除按钮点击次数"
        ]);
      }
    },
    // 下载
    handlerDownload(row) {
      if (window._czc) {
        window._czc.push([
          "_trackEvent",
          "DFP在线转换",
          "转换记录相关",
          "下载按钮点击次数"
        ]);
      }
      this.donwloadFile(row);
    },
    donwloadFile(row) {
      const loading = this.$loading({
        lock: true,
        text: "正在下载",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      hxsGet(
        "doconline/open/file/dld/presign.html?token=" +
          localStorage.getItem("token") +
          "&taskId=" +
          row.taskId
      )
        .then((res) => {
          loading.close();
          if (res.success) {
            window.open(
              res.data.url +
                (res.data.url.indexOf("?") !== -1 ? "&" : "?") +
                "response-content-type=application%2Foctet-stream",
              "_self"
            );
            // const a = document.createElement("a"); // 创建a标签
            // a.href = res.data.url; // 文件url
            // a.click();
          } else {
            if (res.code === "302000") {
              this.$confirm(res.msg, "提示", {
                confirmButtonText: "去购买",
                cancelButtonText: "我在想想",
                type: "warning"
              }).then(() => {
                this.$router.push("product");
              });
            } else {
              this.$message.error(res.msg);
            }
          }
        })
        .catch((err) => {
          this.$message.error(err);
          loading.close();
        });
    },
    // 分页切换
    currentChange(i) {
      this.getList(i);
      this.currentPage = i;
    }
  }
};
</script>

<style lang="scss" scoped>
.history {
  .log-wrap {
    width: 100%;
    min-width: 1200px;
    height: 800px;
    .log-content {
      width: 1200px;
      min-width: 1200px;
      height: 800px;
      padding: 50px 0px;
      margin: 0 auto;
      box-sizing: border-box;
      .log-title {
        font-size: 18px;
        font-family: Microsoft YaHei UI;
        font-weight: 600;
        color: #000000;
      }
      .log-text {
        margin-top: 10px;
        font-size: 12px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        color: #999999;
      }
      .log-list {
        width: 1200px;
        min-width: 1200px;
        margin-top: 54px;
        height: 600px;
      }
      .log-empty {
        padding-top: 200px;
        .log-empty-img {
          width: 220px;
          height: 130px;
          margin: 0 auto;
        }
      }
      .history-download {
        color: #0078f8;
        padding-bottom: 3px;
        height: 30px;
        border-bottom: 1px solid transparent;
        cursor: pointer;
      }
      // .history-download:hover {
      //   border-bottom: 1px solid #0078f8;
      // }
      .history-delete {
        height: 30px;
        line-height: 30px;
        margin-left: 25px;
        border-bottom: 1px solid transparent;
        cursor: pointer;
      }
      .history-delete:hover {
        border-bottom: 1px solid #000;
      }
    }
  }
  .del-dialog {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 66;
    .del-mask {
      width: 469px;
      height: 256px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 5px;
      padding: 50px 45px 0px 45px;
      box-sizing: border-box;
      .del-close {
        width: 34px;
        height: 34px;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
      .del-icon {
        width: 50px;
        height: 50px;
        margin: 0 auto;
      }
      .del-text {
        font-size: 14px;
        font-family: Microsoft YaHei UI;
        color: #000000;
        text-align: center;
      }
      .del-text-top {
        margin: 14px 0 10px 0;
      }
      .del-btn-wrap {
        width: 207px;
        margin: 0 auto;
        display: flex;
        margin-top: 40px;
        .del-btn {
          width: 88px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          font-size: 15px;
          font-family: Microsoft YaHei UI;
          border-radius: 4px;
          box-sizing: border-box;
          cursor: pointer;
        }
        .del-channel {
          color: #0078f8;
          border: 1px solid #0078f8;
        }
        .del-channel:hover {
          background-color: #0078f8;
          color: #fff;
        }
        .del-ok {
          background-color: #0078f8;
          color: #fff;
          margin-left: 30px;
        }
        .del-ok:hover {
          background-color: #479dfa;
        }
      }
    }
  }
}
</style>
